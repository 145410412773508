export const translations = {
  settings: {
    backFromSettings: {
      'en-150': 'Back to events',
      'sk-SK': 'Späť na udalosti'
    },
    settingsLabel: {
      'en-150': 'Application settings',
      'sk-SK': 'Nastavenia aplikácie'
    },
    loginSettingsLabel: {
      'en-150': 'Login',
      'sk-SK': 'Prihlásenie'
    },
    languageSettingsLabel: {
      'en-150': 'Language settings',
      'sk-SK': 'Nastavenia jazyka'
    },
    languageSelectLabel: {
      'en-150': 'Choose a language',
      'sk-SK': 'Vybrať jazyk'
    },
    logout: {
      'en-150': 'Logout',
      'sk-SK': 'Odhlásiť sa'
    },
    loggedInAs: {
      'en-150': 'You are logged in as',
      'sk-SK': 'Ste prihlásený ako'
    },
    applicationLabel: {
      'en-150': 'Application',
      'sk-SK': 'Aplikácia'
    },
    applicationVersion: {
      'en-150': 'Current version',
      'sk-SK': 'Aktuálna verzia'
    },
    statisticsLabel: {
      'en-150': 'Usage statistics',
      'sk-SK': 'Štatistiky používania'
    },
    statisticsDescriptionLevel3: {
      'en-150':
        'Visualizations of these statistics are only available to the users with "Events Administrator" privileges. ' +
        'Statistics consist of two groups - General and Advanced (coming soon). ' +
        'General consists of general numbers and results for the current month, year and since the start of data collection (around 2018).',
      'sk-SK':
        'Vizualizácie týchto štatistík sú dostupné iba pre používateľov s privilégiami "Správca". ' +
        'Štatistiky pozostávajú z dvoch skupín - Základné a Podrobné (čoskoro). ' +
        'Základné pozostávajú zo všeobecných čísel a výsledkov za aktuálny mesiac, rok a od začiatku zbierania údajov (cca rok 2018). '
    },
    statisticsDescriptionLevel4: {
      'en-150':
        'Visualizations of these statistics are only available to the users with "Administrator" privileges. ' +
        'Statistics consist of three groups - General, Advanced (coming soon) and User. ' +
        'General consists of general numbers and results for the current month, year and since the start of data collection (around 2018). ' +
        'User statistics consist of recording user behavior on the site. ' +
        'These "logs" are technical based and data has been recorded since April 2025.',
      'sk-SK':
        'Vizualizácie týchto štatistík sú dostupné iba pre používateľov s privilégiami "Administrátor". ' +
        'Štatistiky pozostávajú z troch skupín - Základné, Podrobné (čoskoro) a používateľské. ' +
        'Základné pozostávajú zo všeobecných čísel a výsledkov za aktuálny mesiac, rok a od začiatku zbierania údajov (cca rok 2018). ' +
        'Používateľké štatistiky pozostávajú zo zaznamenávania správania používateľa na stránke. ' +
        'Tieto "logy" sú viac technického charakteru a dáta sa začali zaznamenávať od Apríla 2025.'
    }
  }
}
