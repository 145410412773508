export const translations = {
  statistics: {
    insertStatisticsPassword: {
      'en-150': 'Insert statistics password',
      'sk-SK': 'Vložiť heslo pre štatistiky'
    },
    submitStatisticsPassword: {
      'en-150': 'Load',
      'sk-SK': 'Načítať'
    },
    incorrectStatisticsPassword: {
      'en-150': 'Incorrect password. The statistics password is unique and is set by IT team.',
      'sk-SK': 'Nesprávne heslo. Heslo pre štatistiky je unikátne a je nastavené IT tímom.'
    },
    loadingStatistics: {
      'en-150': 'Loading statistics ...',
      'sk-SK': 'Načítavam štatistiky ...'
    },
    reloadStatistics: {
      'en-150': 'Reload statistics',
      'sk-SK': 'Obnoviť štatistiky'
    },
    statisticsGeneralInfo: {
      'en-150': 'statisticsGeneralInfo',
      'sk-SK': 'statisticsGeneralInfo'
    },
    generalStatistics: {
      'en-150': 'General Statistics',
      'sk-SK': 'Základné štatistiky'
    },
    advancedStatistics: {
      'en-150': 'Advanced Statistics',
      'sk-SK': 'Podrobné štatistiky'
    },
    userStatistics: {
      'en-150': 'User statistics',
      'sk-SK': 'Používateľské štatistiky'
    },
    addedEvents: {
      'en-150': 'Added events',
      'sk-SK': 'Pridané udalosti'
    },
    deletedHardEvents: {
      'en-150': 'Deleted events',
      'sk-SK': 'Zmazané udalosti'
    },
    deletedSoftEvents: {
      'en-150': 'Canceled events',
      'sk-SK': 'Zrušené udalosti'
    },
    editedEvents: {
      'en-150': 'Edited events',
      'sk-SK': 'Upravené udalosti'
    },
    futureEvents: {
      'en-150': 'Future events',
      'sk-SK': 'Budúce udalosti'
    },
    pastEvents: {
      'en-150': 'Past events',
      'sk-SK': 'Ukončené udalosti'
    },
    totalEvents: {
      'en-150': 'Total events',
      'sk-SK': 'Všetky udalosti'
    },
    monthLabel: {
      'en-150': 'This month',
      'sk-SK': 'Tento mesiac'
    },
    yearLabel: {
      'en-150': 'This year',
      'sk-SK': 'Tento rok'
    },
    allTimeLabel: {
      'en-150': 'All time',
      'sk-SK': 'Všetky udalosti'
    },
    loadingValue: {
      'en-150': 'Loading value ...',
      'sk-SK': 'Načítavam hodnoty ...'
    },
    filterMethodHome: {
      'en-150': 'Domestic',
      'sk-SK': 'Domáci'
    },
    filterMethodEconomic: {
      'en-150': 'Economy',
      'sk-SK': 'Ekonomika'
    },
    filterMethodAbroad: {
      'en-150': 'Foreign',
      'sk-SK': 'Zahraničie'
    },
    filterMethodSport: {
      'en-150': 'Sport',
      'sk-SK': 'Šport'
    },
    filterMethodCulture: {
      'en-150': 'Culture',
      'sk-SK': 'Kultúra'
    },
    filterMethodHungary: {
      'en-150': 'Hungarian',
      'sk-SK': 'Maďarské'
    },
    numberOfEvents: {
      'en-150': 'Number of events',
      'sk-SK': 'Počet udalostí'
    },
    userTableAction: {
      'en-150': 'Action',
      'sk-SK': 'Akcia'
    },
    userTableValue: {
      'en-150': 'Value',
      'sk-SK': 'Hodnota'
    },
    userTableTimestamp: {
      'en-150': 'Time',
      'sk-SK': 'Čas'
    },
    userTableName: {
      'en-150': 'Name',
      'sk-SK': 'Meno'
    }
  }
}
